export const getDomain = () => {
  let domain = "";
  if (window !== undefined) {
    const urlParts = window.location.href.match(/https?:\/\/([^/:]+)/);
    if (urlParts) {
      domain = urlParts[1];
    }
  }
  const domainParts = domain.split(".");
  return domainParts.length > 1
    ? "." + domainParts.slice(-2).join(".")
    : domain;
};

export const getCookies = () => {
  const cookies = new Map();
  for (const c of document.cookie.split(";")) {
    const cr = c.trim();
    const pos = cr.search("=");
    if (pos !== -1) {
      const key = cr.substr(0, pos);
      const value = cr.substr(pos + 1);
      cookies.set(key, value);
    }
  }
  return cookies;
};
export const getCookie = (key: string) => {
  const cookies = getCookies();
  const value = cookies.get("jCookie-" + key);
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else if (value !== undefined) {
    return value;
  } else {
    return false;
  }
};
export const setCookie = (key: string, value: boolean) => {
  const myDate = new Date();
  myDate.setMonth(myDate.getMonth() + 12);
  const name = "jCookie-" + key;
  document.cookie =
    name +
    "=" +
    value +
    ";expires=" +
    myDate +
    ";secure;domain=" +
    getDomain() +
    ";path=/";
};
export const saveCookies = (settings: {
  accepted: boolean;
  analytics: boolean;
  advertisement: boolean;
}) => {
  if (settings !== undefined) {
    setCookie("accepted", settings.accepted);
    setCookie("analytics", settings.analytics);
    setCookie("advertisement", settings.advertisement);
  }
};
