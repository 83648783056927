import {defineStore} from "pinia";

export type Category = { code: string, optional: boolean, title: string, description: string, cookies: Array<Cookie> };
export type Cookie = { name: string, domain: string, description: string };
export type Settings = { accepted: boolean, analytics: boolean, advertisement: boolean };

const categories: Category[] = [];
const settings: Settings = {
  accepted: false,
  analytics: false,
  advertisement: false,
};
export const useCookieStore = defineStore({
  id: "cookie",
  state: () => ({
    bar: true,
    modal: false,
    categories,
    settings,
  }),
  getters: {},
  actions: {
    setBar(visibility: boolean) {
      this.bar = visibility;
    },
    setModal(visibility: boolean) {
      console.debug(visibility);
      this.modal = visibility;
    },
    setCookies(categories: Array<Category>) {
      this.categories = categories;
    },
    changeSetting(key: string, value: boolean) {
      switch (key) {
        case "accepted":
          this.settings.accepted = value;
          break;
        case "analytics":
          this.settings.analytics = value;
          break;
        case "advertisement":
          this.settings.advertisement = value;
          break;
      }
    },
    setSettings(accepted: boolean, analytics: boolean, advertisement: boolean) {
      this.settings.accepted = accepted;
      this.settings.analytics = analytics;
      this.settings.advertisement = advertisement;
    },
    showModal() {
      this.modal = true;
    },
    hideModal() {
      this.modal = false;
    },
  },
});
