<template>
  <ModalWindow v-if="cookieStore.modal" title="Evästeasetukset">
    <div class="jCookieModalContent">
      <p>{{ textToShow() }}{{ !data.readMore ? "..." : "" }}</p>
      <p class="readmore" @click="data.readMore = !data.readMore">
        {{ !data.readMore ? "Näytä lisää" : "Näytä vähemmän" }}
      </p>
      <CookieCategory
        v-for="category in cookieStore.categories"
        :key="category.code"
        v-bind="category"
      />
    </div>
    <div class="jCookieButton">
      <button class="button" @click="saveAccept()">Tallenna ja hyväksy</button>
    </div>
  </ModalWindow>
</template>

<script setup lang="ts">
import ModalWindow from "@/components/ModalWindow.vue";
import CookieCategory from "@/components/CookieCategory.vue";
import { saveCookies } from "@/cookies";
import { checkScripts } from "@/handleScripts";
import { useCookieStore } from "@/stores/cookie";
import {reactive} from "vue";

const data = reactive({ readMore: false });

const fullText =
  "Tämä verkkosivusto käyttää evästeitä parantaakseen käyttökokemustasi\n" +
  "navigoidessasi verkkosivustolla. Näistä evästeistä välttämättömiksi\n" +
  "luokitellut evästeet tallennetaan selaimeesi, koska ne ovat välttämättömiä\n" +
  "verkkosivuston perustoimintoja varten. Käytämme myös kolmansien osapuolien\n" +
  "evästeitä, jotka auttavat meitä analysoimaan ja ymmärtämään, kuinka käytät\n" +
  "tätä verkkosivustoa. Nämä evästeet tallennetaan selaimeesi vain\n" +
  "suostumuksellasi. Sinulla on myös mahdollisuus poistaa nämä evästeet.\n" +
  "Joidenkin näiden evästeiden poistaminen voi vaikuttaa käyttökokemukseen.\n" +
  "Keräämme evästeitä, jotta ymmärtäisimme paremmin, miten kävijät käyttävät\n" +
  "sivustoamme ja millainen sisältö ja tekniset ratkaisut heitä palvelevat\n" +
  "parhaiten. Kävijätietojen analysointi auttaa meitä kehittämään sivustoamme\n" +
  "entistä palvelevammaksi. Käytämme tietoja myös markkinoinnin ja myynnin\n" +
  "kohdentamiseen. Voit hallinnoida evästeasetuksiasi alta. Lue lisää\n" +
  "tietosuojasta.";
const cookieStore = useCookieStore();
const saveAccept = () => {
  cookieStore.changeSetting("accepted", true);
  saveCookies(cookieStore.settings);
  cookieStore.setBar(false);
  cookieStore.setModal(false);
  checkScripts(
    cookieStore.settings.accepted,
    cookieStore.settings.analytics,
    cookieStore.settings.advertisement
  );
};
const textToShow = () => {
  if (fullText.split(" ").length < 50) {
    return fullText;
  }
  if (!data.readMore) {
    return fullText.split(" ").slice(0, 50).join(" ");
  }
  return fullText;
};
</script>

<style lang="scss">
.readmore {
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  &:hover {
    color: #0088e3;
  }
  margin-top: 0.5rem;
}
.jCookieModalContent {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
}
.jCookieButton {
  text-align: right;
}
</style>
