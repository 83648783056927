import {createApp} from "vue";
import {createPinia} from "pinia";
import App from "./App.vue";
import {getCookie} from "@/cookies";
import {checkScripts} from "@/handleScripts";
import {useCookieStore} from "@/stores/cookie";
import {fetchCookies} from "@/fetch";

const isFramed = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const isExplicitlyBlocked = (): boolean => {
  return window.location.href.search("iniframe") > -1;
};

const app = createApp(App);

app.use(createPinia());

const cookieStore = useCookieStore();

// Initialize store from cookies.
cookieStore.setSettings(
  getCookie("accepted"),
  getCookie("analytics"),
  getCookie("advertisement")
);
// Set state of popup bar.
cookieStore.setBar(!getCookie("accepted"));

// Check if scripts should be enabled.
document.addEventListener("DOMContentLoaded", () => {
  checkScripts(
    cookieStore.settings.accepted,
    cookieStore.settings.analytics,
    cookieStore.settings.advertisement
  );
});

if (!isFramed() && !isExplicitlyBlocked()) {
  // Fetch cookie data.
  fetchCookies((data: any) => {
    cookieStore.setCookies(data);
  });

  // Create Element if not exists.
  const elem = document.createElement("div");
  elem.id = "jCookie";
  document.body.appendChild(elem);

  // Mount the app.
  app.mount("#jCookie");
}
