<template>
  <div>
    <div id="jCookieModalRoot">
      <div id="jCookieBackground" @click="cookieStore.setModal(false)"></div>
      <div id="jCookieModal">
        <div class="top">
          {{ title }}
          <button type="button" id="jCookieModalClose" @click="cookieStore.setModal(false)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <span class="sr-only">Close</span>
          </button>
        </div>
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useCookieStore} from '@/stores/cookie'
import {defineProps, onMounted, onUnmounted} from "vue";

defineProps<{
  title: string;
}>();
const cookieStore = useCookieStore();

onMounted(() => {
  document.body.classList.add("jCookieModalOpen");
});
onUnmounted(() => {
  document.body.classList.remove("jCookieModalOpen");
});
</script>

<style lang="scss">
#jCookieBackground {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
#jCookieModalRoot {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  z-index: 1002;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  #jCookieModal {
    pointer-events: none;
    background-color: rgb(255, 255, 255);
    width: 800px;
    border-radius: 0.8rem;
    padding-top: 0;
    overscroll-behavior: none;
    position: relative;
    margin: auto;

    .top {
      pointer-events: auto;
      padding: 1rem 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffd719;
      border-top-right-radius: 0.8rem;
      border-top-left-radius: 0.8rem;
      text-transform: uppercase;
      font-size: 20px;
      font-weight: bold;

      #jCookieModalClose {
        background: transparent;
        width: 24px;
        height: 24px;
        padding: 0;
      }
    }

    .content {
      pointer-events: auto;
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 560px) {
        max-height: 560px;
      }
      max-height: 500px;
      padding: 20px;
    }
  }
}
</style>
