<template>
  <div id="jCookie">
    <CookiePopup v-if="cookieStore.modal" />
    <CookieBar v-if="cookieStore.bar" />
  </div>
</template>

<script lang="ts">
import CookieBar from "@/components/CookieBar.vue";
import CookiePopup from "@/components/CookiePopup.vue";
import { useCookieStore } from "@/stores/cookie";
export default {
  setup() {
    const cookieStore = useCookieStore();
    return {
      cookieStore,
    };
  },
  components: {
    CookieBar,
    CookiePopup,
  },
};
</script>

<style lang="scss">
#jCookie {
  font-family: Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif;

  .sr-only {
    display: none;
  }

  button {
    background: none;
    border: 0;
    border-radius: 0;
    cursor: pointer;
  }

  .button {
    padding: 13px 22px;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 136, 227);
    font-weight: bold;
  }

  .plain {
    text-decoration: underline;
    color: rgb(0, 136, 227);
  }
}
</style>
