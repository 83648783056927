<template>
  <section class="jCookieSetting">
    <div class="box" @click="data.isShowing = !data.isShowing">
      <span :class="{ rotate: data.isShowing }" class="arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </span>
      <span class="title">
        {{ title }}
      </span>
      <div class="jCookieSwitch" v-if="optional">
        <input
          type="checkbox"
          class="jCookieCheckbox"
          :checked="cookieStore.settings[code]"
          :id="'jCookieCheckbox-' + code"
          :data-id="code"
          @change="cookieStore.changeSetting(code, !cookieStore.settings[code])"
        />
        <label
          :for="'jCookieCheckbox-' + code"
          class="jCookieSlider"
          data-cli-enable="Käytössä"
          data-cli-disable="Ei käytössä"
          ><span class="sr-only">{{ title }}</span></label
        >
      </div>
      <span v-else class="jCookieCaption"> Aina käytössä </span>
    </div>
    <div :class="{ show: data.isShowing }" class="JCookieSettingText">
      <p>
        {{ description }}
      </p>
      <table class="jCookieCookies">
        <thead>
          <tr>
            <th>Nimi</th>
            <th>Verkkotunnus</th>
            <th>Kuvaus</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cookie in cookies" :key="cookie.name">
            <td>{{ cookie.name }}</td>
            <td>{{ cookie.domain }}</td>
            <td>{{ cookie.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useCookieStore } from "@/stores/cookie";
import { defineProps, reactive } from "vue";
import type { Cookie } from "@/stores/cookie";

defineProps<{
  code: string;
  title: string;
  optional: boolean;
  description: string;
  cookies: Array<Cookie>;
}>();

const cookieStore = useCookieStore();
const data = reactive({ isShowing: false });
</script>

<style lang="scss">
.jCookieSetting {
  height: auto;

  .box {
    .arrow {
      &.rotate {
        transition: transform 0.15s ease-in-out;
        transform: rotateZ(90deg);
      }

      transition: transform 0.3s ease-in-out;
      display: inline-block;
      height: 20px;
      width: 20px;
    }

    .title {
      margin-left: 10px;
      display: inline-block;
    }

    background-color: #fff;
    border: 1px solid #f4f4f4;
    border-radius: 5px;
    padding: 12px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease-out 0.3s, color 0.2s ease-out 0s;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .JCookieSettingText {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.15s ease-out;
    padding: 10px 20px 5px 20px;

    & > p {
      margin: 0;
      margin-bottom: 1em;
      opacity: 0;
      transition: opacity 0.3s ease-out;
    }

    &.show {
      & > p {
        opacity: 1;
        transition: opacity 0.3s ease-out;
      }

      transition: max-height 0.3s ease-out;
      max-height: 600px;
    }
  }

  .jCookieCaption {
    margin-left: auto;
    @media screen and (max-width: 560px) {
      font-size: 12px;
    }
  }

  .jCookieCookies {
    font-size: 12px;
    width: 100%;

    tr {
      th {
        text-align: start;
      }

      td {
        padding: 4px;
        background-color: #f3f5f7;
      }

      &:nth-child(odd) {
        td {
          background-color: #e3e6e8;
        }
      }
    }
  }

  .jCookieSwitch {
    margin-left: auto;
    display: inline-block;
    position: relative;
    min-height: 1px;
    padding-left: 38px;
    font-size: 14px;
    width: auto;

    .jCookieSlider {
      height: 26px;
      width: 44px;
      background-color: #2e373d;
      border-radius: 34px;
      font-size: 0;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: -12px;
      transition: 0.4s;

      &:before {
        bottom: 3px;
        height: 20px;
        left: 3px;
        width: 20px;
        border-radius: 50%;
        background-color: #fff;
        content: "";
        position: absolute;
        transition: 0.4s;
      }

      &:after {
        content: attr(data-cli-disable);
        position: absolute;
        top: 4px;
        right: 50px;
        color: #000;
        font-size: 12px;
        text-align: right;
        min-width: 120px;
        @media screen and (max-width: 560px) {
          display: none;
        }
      }
    }

    input:checked + .jCookieSlider {
      background-color: #0088e3;

      &:before {
        transform: translateX(18px);
      }

      &:after {
        content: attr(data-cli-enable);
      }
    }

    .jCookieCheckbox {
      display: none;
    }
  }
}
</style>
