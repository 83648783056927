import axios from "axios";

const baseUrl = "https://www.0100100.fi/wp-json/jcookie/v1/";

export interface Response {
  (data: any): void;
}

export const fetchCookies = (callback: Response) => {
  axios.get(baseUrl + "cookies")
    .then((response) => {
      callback(response.data);
    })
    .catch((reason) => {
      console.debug(reason);
    });
};
