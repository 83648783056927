<template>
  <div id="jCookieBar">
    <div class="message">
      Käytämme evästeitä parantaaksemme sivustomme käytettävyyttä. Painamalla
      “Hyväksy” hyväksyt KAIKKIEN evästeiden käytön. Voit vaihtaa näitä
      asetuksia kohdasta Evästeasetukset.
    </div>
    <div class="jCookieButtonBar">
      <button @click="showModal()" class="plain">Evästeasetukset</button>
      <button @click="readMore()" class="plain">Lue lisää</button>
      <button @click="acceptAll(true)" class="button">Hyväksy</button>
      <button @click="acceptAll(false)" class="button">Hylkää</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useCookieStore} from "@/stores/cookie";
import {saveCookies} from "@/cookies";
import {checkScripts} from "@/handleScripts";

const cookieStore = useCookieStore();
const readMore = () => {
  window.open("https://www.0100100.fi/tietosuojalauseke/", "_blank");
};
const showModal = () => {
  cookieStore.setModal(true);
};
const acceptAll = (accept: boolean) => {
  cookieStore.setSettings(true, accept, accept);
  saveCookies(cookieStore.settings);
  cookieStore.setModal(false);
  cookieStore.setBar(false);
  checkScripts(
    cookieStore.settings.accepted,
    cookieStore.settings.analytics,
    cookieStore.settings.advertisement
  );
};
</script>

<style lang="scss">
#jCookieBar {
  box-shadow: 0px -5px 10px -1px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  background-color: rgb(255, 215, 25);
  padding: 15px 30px;
  position: fixed;
  z-index: 1001;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 16px;

  .jCookieButtonBar {
    justify-content: space-evenly;
    align-items: center;
    width: 620px;
    display: flex;
    @media screen and (max-width: 740px) {
      flex-wrap: wrap;
      button {
        &.plain {
          padding: 0;
        }

        margin-right: 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 740px) {
    flex-wrap: wrap;
    .message {
      margin-bottom: 1rem;
    }
  }
}
</style>
