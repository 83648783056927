export const checkScripts = (
  accepted: boolean,
  advertisement: boolean,
  analytics: boolean
) => {
  // If advertisement accepted, activate scripts.
  if (accepted && advertisement) {
    clearScripts("advertisement");
  }
  // If analytics accepted, activate scripts.
  if (accepted && analytics) {
    clearScripts("analytics");
  }
};

export const clearScripts = (tag: string) => {
  for (const element of document.scripts) {
    if (element.dataset.jcookie === tag) {
      const newScript = document.createElement("script");
      newScript.type = "text/javascript";
      if (element.innerText !== "") {
        newScript.innerText = element.innerText;
      }
      if (element.src !== "") {
        newScript.src = element.src;
      }
      element.remove();
      document.head.appendChild(newScript);
    }
  }
};
